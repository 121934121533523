body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.claim-business ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.claim-business::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.claim-business::-webkit-scrollbar-thumb {
  @apply bg-primary;
}

/* Handle on hover */
.claim-business::-webkit-scrollbar-thumb:hover {
  @apply bg-primary/30;
}

/* font family  */

@font-face {
  font-family: 'Lato-Regular';
  src: url('/public/FontFamily/Lato-Regular.ttf') format('truetype');
  /* / src: url("/FontFamily/Lato-Medium.ttf") format("truetype"); / */
  /* src: url("/FontFamily/lato-semibold//lato-regular.ttf")
      format("truetype"); */
}
@font-face {
  font-family: 'Lato-Semibold';

  src: url('/public/FontFamily/lato-semibold/LatoSemibold.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato-Bold';
  src: url('/public/FontFamily/Lato-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato-Medium';
  src: url('/public/FontFamily/Lato-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato-Extrabold';
  src: url('/public/FontFamily/lato-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('/public/FontFamily/Montserrat-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('/public/FontFamily/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-Medium';
  src: url('/public/FontFamily/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('/public/FontFamily/Montserrat-Regular.ttf') format('truetype');
}
