.edit-from-multiselect .search-wrapper {
  border: none !important;
  position: relative !important;
  padding: 0px !important;
  min-width: 100% !important;
}
.edit-from-multiselect .searchbox {
  border: none;
  font-size: 10px;
  min-height: 50px;
  min-width: 100% !important;
}

input[type='checkbox'] {
  @apply !accent-primary;
}
.edit-from-multiselect #search_input {
  border: 1px solid #f1f2f4;
  @apply !relative !flex  !min-w-full !border border-[#F1F2F4] !rounded-[30px] !p-[7px] 2xl:!py-[11px] !w-full !pl-10;
}
.multiselect-container {
  @apply !w-full !min-w-full;
}
.edit-from-multiselect #search_input:focus {
  border: 1px solid #2355c4;
}

.create-job-select #search_input {
  @apply !pl-3 !py-[10px] 2xl:!min-h-[50px] !min-h-[40px];
}

.edit-from-multiselect .chip {
  @apply !bg-[#2355C41A]  !text-[#2355C4] !text-sm !font-lato_bold;
}

.edit-from-multiselect .closeIcon {
  filter: brightness(0) saturate(100%) invert(22%) sepia(56%) saturate(3262%) hue-rotate(218deg) brightness(91%) contrast(94%) !important;
  /* @apply !invert-[100%]; */
  @apply !max-w-max;
}
.edit-from-multiselect .false {
  @apply !max-w-max !pl-1;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number'] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}

.site-scroll-bar {
  overflow-y: overlay;
  scrollbar-width: thin;
  scrollbar-color: #acaebe;
  @apply scroll-m-3;
}

/* .react-joyride__beacon {
  @apply !hidden;
} */

.multiSelectContainer .optionListContainer {
  @apply !z-30;
}
